export const environment = {
  production: true,
  appVersion: 'v8.0.25',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'api',
  appThemeName: 'Metronic',
  appPurchaseUrl: 'https://1.envato.market/EA4JP',
  appHTMLIntegration: 'https://preview.keenthemes.com/metronic8/demo1/documentation/base/helpers/flex-layouts.html',
  appPreviewUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1/',
  appPreviewAngularUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1',
  appPreviewDocsUrl: 'https://preview.keenthemes.com/metronic8/angular/docs',
  appPreviewChangelogUrl: 'https://preview.keenthemes.com/metronic8/angular/docs/changelog',
  apiKeyGOOGLE_MAPS : 'AIzaSyDAKE7ZYAGWpxy4V_1gf-VB5RwgL2MX2LE',
  jwt: {
    audience: 'DefaultAudience',
    issuer: 'DefaultIssuer'
  },
  api: {
    WS_IT: {
      url: 'https://api.kunturspace.com/WS_IT/api',
      //url: 'https://localhost:44372/api',
      basicAuthorization: {
        username: 'KUNTURITApp',
        password: '4975*alj.23'
      }
    },
    WS_SD: {
      url: 'https://calzadosjkluanaapi.azurewebsites.net/WS_SD/api',
      // url: 'http://localhost:54145/api',
      basicAuthorization: {
        username: 'JKLUASDApp',
        password: '975318642'
      }
    }
  }
};
