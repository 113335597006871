import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-dialog-enable-disable',
  templateUrl: './dialog-enable-disable.component.html',
  styleUrls: ['./dialog-enable-disable.component.scss']
})
export class DialogEnableDisableComponent implements OnInit {
  @Input() dataUsuario: any;
  @Input() titulo: any;
  @Input() descripcion: any;
  @Input() button: any;
  @Input() tipo: any;
  @Output() onSubmitSubject: Subject<boolean> = new Subject<boolean>();
  title: string = '';
  message: string = '';
  form: FormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private _formBuilder: FormBuilder,
    public toastr: ToastrManager,
  ) { }

  ngOnInit(): void {
   
  }

  aceptar() {
    this.activeModal.close(true);
    this.onSubmitSubject.next(true);
  }
 
  close() {
    this.activeModal.close(false);
    this.onSubmitSubject.next(true);
  }

}
