import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { GlobalProperties } from '../util/global-properties';
import { environment } from 'src/environments/environment.prod';
import { ApiEnum } from 'src/app/shared/enums/api.enum';


@Injectable({
  providedIn: 'root'
})
export class HeaderBasicAuthorizationService {

  constructor() { }

  get(username: string, password: string) {
    let headers = new HttpHeaders();
    let valueOfApiEnum: string = this.toBtoa(username, password);
    headers = headers.set(GlobalProperties.headers.basicAuthorization.name,
                        `${GlobalProperties.headers.basicAuthorization.prefix}${valueOfApiEnum}`)
    return headers;
  }

  private toBtoa(username: string, password: string): string {
    return btoa(username + ':' + password);
  }

}