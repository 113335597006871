<div class="modal-header">
    <h4>{{ titulo }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="modal-body">
        <div class="row">                      
            <div class="col-md-12" style="text-align: center;">
                <span>{{ descripcion }}</span>
            </div>          

        </div>
  </div>
  <div class="modal-footer">
    <span class="pull-right" style="padding-right:2%">
      <button type="button" class="btn btn-primary btn-sm" (click)="aceptar()">{{ button }}</button>&nbsp;
      <button type="button" class="btn btn-danger btn-sm" ngbAutofocus (click)="close()" id="btnCancel">Cerrar</button>
    </span>
  </div>