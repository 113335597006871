import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators'
import { StorageKeyEnum } from 'src/app/shared/enums/storage-key.enum';
import { HeaderBasicAuthorizationService } from 'src/app/shared/services/header-basic-authorization.service';
import { JwtService } from 'src/app/shared/services/jwt.service';
import { PermissionNavigationService } from 'src/app/shared/services/permission-navigation.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { environment } from 'src/environments/environment';
import { ApiEnum } from 'src/app/shared/enums/api.enum';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private router: Router,
        private storageService: StorageService,
        private jwtService: JwtService,
        private httpClient: HttpClient,
        private headerBasicAuthorization: HeaderBasicAuthorizationService,
        private permissionNavigationService: PermissionNavigationService) { }

    login(login: string, password: string) {
        return this.httpClient.post(`${environment.api.WS_IT.url}/Seguridad/ZITG_Login`, 
            {
                login: login,
                password: password
            }, {
                headers: this.headerBasicAuthorization.get(environment.api.WS_IT.basicAuthorization.username, environment.api.WS_IT.basicAuthorization.password)
            }
        )
            .pipe(
                tap((response: any) => {
                    if (response.Ok) {
                        this.storageService.set(StorageKeyEnum.JWT_AUTHORIZATION, response.JWT);
                        this.jwtService.load(response.JWT);
                    }
                })
            )
    }

    logoff() {
        this.permissionNavigationService.reset();
        this.storageService.remove(StorageKeyEnum.JWT_AUTHORIZATION);
        this.jwtService.clear();
    }

    refreshToken() {
        return this.httpClient.get(`${environment.api.WS_IT.url}/Seguridad/refreshToken`, {
            headers: this.headerBasicAuthorization.get(environment.api.WS_IT.basicAuthorization.username, environment.api.WS_IT.basicAuthorization.password)
        }).pipe(
            tap((response: any) => {
                if (response.Ok) {
                    this.storageService.set(StorageKeyEnum.JWT_AUTHORIZATION, response.JWT);
                    this.jwtService.load(response.JWT);
                }
            })
        );
    }

    forgotPassword(dominio:string, email: string, login: string) {
        return this.httpClient.post(`${environment.api.WS_IT.url}/Seguridad/ForgotPassword`, 
            {
                dominio: dominio,
                email: email,
                login: login
            }, {
                headers: this.headerBasicAuthorization.get(environment.api.WS_IT.basicAuthorization.username, environment.api.WS_IT.basicAuthorization.password)
            }
        )
    }

    changePassword(passwordActual:string, passwordNueva: string) {
        return this.httpClient.post(`${environment.api.WS_IT.url}/Seguridad/ChangePassword`, 
            {
                passwordActual: passwordActual,
                passwordNueva: passwordNueva
            }, {
                headers: this.headerBasicAuthorization.get(environment.api.WS_IT.basicAuthorization.username, environment.api.WS_IT.basicAuthorization.password)
            }
        )
    }

    resetPassword(Usuario:number, Password: string) {
        return this.httpClient.post(`${environment.api.WS_IT.url}/Seguridad/ResetPassword`, 
            {
                Usuario: Usuario,
                Password: Password
            }, {
                headers: this.headerBasicAuthorization.get(environment.api.WS_IT.basicAuthorization.username, environment.api.WS_IT.basicAuthorization.password)
            }
        )
    }

    findPermisosRoot() {
        return ['/', '/profile', '/change-password'];
    }

    findPermissions() {
        return this.httpClient.get(`${environment.api.WS_IT.url}/Seguridad/GetPermissions`, {
            params: {
            },
            headers: this.headerBasicAuthorization.get(environment.api.WS_IT.basicAuthorization.username, environment.api.WS_IT.basicAuthorization.password)
        });
    }

    findPermisos(application: string) {
        return this.httpClient.get(`${environment.api.WS_IT.url}/ZITG_ObtenerOpcionesUsuario`, {
            params: {
                prmstrApplication: application
            },
            headers: this.headerBasicAuthorization.get(environment.api.WS_IT.basicAuthorization.username, environment.api.WS_IT.basicAuthorization.password)
        });
    }

    isLoggedIn() {
        let jwt: string = this.storageService.get(StorageKeyEnum.JWT_AUTHORIZATION);
        if (jwt != null) {
            this.jwtService.load(jwt);
            return this.jwtService.isValid();
        } else {
            return false;
        }
    }

    redirectToLogin() {
        this.router.navigate(['/login']);
    }

    redirectToMain() {
        this.router.navigate(['/']);
    }

    redirectToNotAllowed() {
        this.router.navigate(['/error/not-allowed']);
    }

}