import { Injectable } from '@angular/core';
import { JwtDataModel } from '../models/jwt-data.model';
import { JwtUserModel } from '../models/jwt-user.model';
import { environment } from '../../../environments/environment';
import jwtDecode from 'jwt-decode';
import { StorageService } from './storage.service';
import { StorageKeyEnum } from '../enums/storage-key.enum';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  private data: any = null;
  private user: any = null;

  constructor(
    private storageService: StorageService,
  ) { }

  clear(): void {
    this.data = null;
    this.user = null;
  }

  load(jwt: string): void {
    try {
      this.data = jwtDecode(jwt) as JwtDataModel;
      this.user = JSON.parse(this.data.userData);
    } catch (e) {
    }
  }

  isValid(): any {
    return !this.isExpired() && this.isValidAudience() && this.isValidIssuer();
  }

  getUser(): JwtUserModel {
    let jwt: string = this.storageService.get(StorageKeyEnum.JWT_AUTHORIZATION);
    this.load(jwt);
    console.log(this.user);
    return this.user;
  }
  
  private isExpired(): boolean {
    if (this.data == null) {
      return true;
    }

    const date = this.getExpirationDate();
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  private getExpirationDate(): any {
    if (this.data.exp === undefined) return null;

    const date = new Date(0); 
    date.setUTCSeconds(this.data.exp);
    return date;
  }

  private isValidAudience() {
    if (this.data.aud === undefined) return false;
    return this.data.aud === environment.jwt.audience;
  }

  private isValidIssuer() {
    if (this.data.iss === undefined) return false;
    return this.data.iss === environment.jwt.issuer;
  }
}
